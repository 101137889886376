const appendScript = (fragment, config) => {
  const script = document.createElement('script');
  script.src = config.src;
  if (config.sync) {
    script.async = false;
  }
  fragment.append(script);
  return fragment;
};
const scriptsToLoad = [
  {
    sync: true,
    src: 'https://cdn.rawgit.com/webcomponents/webcomponentsjs/d5b7ca65/webcomponents-sd-ce.js',
  },
  { sync: false, src: '/ws/components/icon/index.js' },
  { sync: false, src: '/ws/components/branding/index.js' },
  { sync: false, src: '/ws/components/contact-form-container/index.js' },
  { sync: false, src: '/ws/components/nav/index.js' },
];
const fragment = document.createDocumentFragment();
scriptsToLoad.reduce(appendScript, fragment);
document.head.append(fragment);
